$fixed_height: 45px;

.main-input-form {
  // input
  .ant-input {
    height: $fixed_height;
  }

  //input password
  .ant-input-affix-wrapper {
    padding-top: 0;
    padding-bottom: 0;
  }

  //select
  .ant-select {
    height: $fixed_height;
    .ant-select-selector {
      height: 100%;
      display: flex;
      align-items: center;
    }
    .ant-select-item-option-disabled {
      background-color: #c7c7c53b;
    }
  }

  //picker
  .ant-picker {
    height: $fixed_height;
    width: 100%;
  }

  //input number
  .ant-input-number {
    width: 100%;
    height: $fixed_height;
    .ant-input-number-input-wrap {
      height: 100%;
      .ant-input-number-input {
        height: 100%;
        // text-align: center;
      }
    }
  }
  .ant-input-number-group-wrapper {
    width: 100%;
  }

  .ant-form-item-has-error {
    .ant-input-affix-wrapper {
      border: 1px solid #ff4d4f !important;
    }
  }
}
