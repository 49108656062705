// layout
.ant-layout-sider {
  background-color: white;
}
.ant-layout-header {
  line-height: unset !important;
  background-color: #54544d;
}
// layout

.ant-btn {
  //   display: flex;
  //   align-items: center;
  //   justify-items: center;
  text-align: center;
  border-radius: 0px !important;
}

.ant-modal-content {
  .ant-btn-primary {
    span {
      color: white;
    }
  }
}

.ant-form-vertical .ant-form-item-label {
  padding: 0 0 0px !important;
  line-height: 2.5715 !important;
}
.ant-form-item {
  margin-bottom: unset !important;
}

.ant-breadcrumb-separator {
  color: #b69e4f !important;
}

.ant-breadcrumb span {
  color: #c5c7d0;
  font-size: 12px !important;
  transition: unset !important;
}

.ant-breadcrumb span:hover {
  color: #b69e4f !important;
}

.ant-form-item-label
  > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  color: #b69e4f !important;
  font-family: "std-bold" !important;
}

.ant-form-item-label > label {
  font-size: 16px !important;
}

.ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector
  .ant-select-selection-search-input {
  height: 45px;
}

.ant-btn-link {
  color: #333333 !important;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: #e1ded9;
}

.custom-select-ant
  .ant-select-disabled.ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  span {
    color: rgba(0, 0, 0, 0.25) !important;
  }
}
// .ant-input:hover {
//   border-color: #b69e4f !important;
// }

// .ant-input:focus,
// .ant-input-focused {
//   border-color: #b69e4f;
//   box-shadow: 0 0 0 2px rgba(182, 158, 79, 0.2);
// }

// .ant-select-focused:not(.ant-select-disabled)
//   .ant-select:not(.ant-select-customize-input)
//   .ant-select-selector {
//   border-color: #b69e4f !important;
//   box-shadow: 0 0 0 2px rgba(182, 158, 79, 0.2) !important;
// }

// .ant-picker-focused {
//   border-color: #b69e4f !important;
//   box-shadow: 0 0 0 2px rgba(182, 158, 79, 0.2) !important;
// }

// .ant-picker:hover,
// .ant-picker-focused {
//   border-color: #b69e4f;
// }

// .ant-picker-range .ant-picker-active-bar {
//   background: #b69e4f;
// }

// .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
//   border: 1px solid #b69e4f;
// }

// .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
// .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
// .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
//   color: #fff;
//   background: #b69e4f;
// }

// .ant-picker-cell-in-view.ant-picker-cell-in-range::before {
//   background: #fffce6;
// }

// .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before,
// .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before {
//   background: #fffce6;
// }

.ant-popover-placement-rightTop {
  padding-left: 2px;
}

.ant-popover-inner-content {
  padding: unset;
}

.ant-popover-placement-rightTop .ant-popover-arrow {
  left: unset;
}

.ant-popover-inner {
  box-shadow: unset;
}

.ant-tooltip-inner {
  width: max-content;
}

// .ant-upload.ant-upload-drag {
//   height: 25vh;
//   border: 1px solid #e1ded9;
// }

.ant-upload.ant-upload-drag p.ant-upload-drag-icon .anticon {
  color: #e1ded9;
  font-size: 48px;
}

.ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover {
  border-color: #e1ded9;
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border: 1px solid $gold-color;
}

.ant-picker-today-btn {
  color: $gold-color;
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  background: $gold-color;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled)
  .ant-select-item-option-state {
  color: #54544d;
}

// .ant-upload.ant-upload-drag {
//   border: 1;
// }

.ant-slider-handle {
  border: solid 2px $gold-color;
}
.ant-slider-handle.ant-tooltip-open {
  border-color: $gold-color;
}
.ant-slider-handle:focus {
  border-color: $gold-color;
  outline: none;
  box-shadow: 0 0 0 5px $light-gold-color;
}
.ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open) {
  border-color: $gold-color;
}
.ant-slider:hover .ant-slider-track {
  background-color: $gold-color;
}
.ant-slider-track {
  background-color: $light-gold-color;
}

.ant-switch-checked {
  background-color: $gold-color;
}

.ant-input:hover,
.ant-input:focus,
.ant-input:active,
.ant-input:focus-within {
  border-color: $gold-color !important;
  box-shadow: none;
}

.ant-select:hover,
.ant-select:focus,
.ant-select:active,
.ant-select:focus-within {
  .ant-select-selector {
    border-color: $gold-color !important;
    box-shadow: none !important;
  }
}

.ant-form-item-has-error
  :not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
.ant-form-item-has-error
  :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper,
.ant-form-item-has-error
  :not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover,
.ant-form-item-has-error
  :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover {
  // background-color: #fff !important;
  border-color: #ff4d4f !important;
}

.ant-input[disabled] {
  background-color: #f5f5f5 !important;
}

.ant-form-item-has-error .ant-input-number,
.ant-form-item-has-error .ant-picker {
  border-color: #ff4d4f !important;
}

.ant-picker:hover,
.ant-picker:focus,
.ant-picker:active,
.ant-picker:focus-within {
  border-color: $gold-color;
  box-shadow: none;
}

.ant-input-number:hover,
.ant-input-number:focus,
.ant-input-number:active,
.ant-input-number:focus-within {
  border-color: $gold-color;
  box-shadow: none;
}

//Datepicker
.ant-select-selector {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
}
.ant-picker {
  border: 1px solid #e4e9f2 !important;
  border-radius: 0px;
  box-shadow: unset !important;
  width: 100%;
  height: 45px;
}
.ant-picker-now * {
  color: $gold-color;
}
.ant-picker-ok * {
  color: white;
}
.ant-btn-primary {
  border-color: $gold-color;
  background-color: $gold-color;
}

.ant-btn-primary:hover,
.ant-btn-primary:focus,
.ant-btn-primary:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: #ceb35a;
  background: #ceb35a;
}
.ant-btn-primary[disabled],
.ant-btn-primary[disabled]:hover,
.ant-btn-primary[disabled]:focus,
.ant-btn-primary[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: $light-gold-color;
  background: $light-gold-color;
}
.ant-picker-range .ant-picker-active-bar {
  background: $gold-color;
}
.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  background: $gold-color;
}
.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border: 1px solid $gold-color;
}
.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before,
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before {
  background-color: #e1ded9;
}
.ant-picker-cell-in-view.ant-picker-cell-in-range::before {
  background: #e1ded9;
}
.ant-picker-time-panel-column
  > li.ant-picker-time-panel-cell-selected
  .ant-picker-time-panel-cell-inner {
  background: #e1ded9;
}
tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:first-child::after,
tr
  > .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:first-child::after,
.ant-picker-cell-in-view.ant-picker-cell-start.ant-picker-cell-range-hover-edge-start.ant-picker-cell-range-hover-edge-start-near-range::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-start:not(.ant-picker-cell-range-hover-edge-start-near-range)::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start::after {
  border-left: 1px dashed #e1ded9;
}
tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:last-child::after,
tr
  > .ant-picker-cell-in-view.ant-picker-cell-range-hover-start:last-child::after,
.ant-picker-cell-in-view.ant-picker-cell-end.ant-picker-cell-range-hover-edge-end.ant-picker-cell-range-hover-edge-end-near-range::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-end:not(.ant-picker-cell-range-hover-edge-end-near-range)::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end::after {
  border-right: 1px dashed #e1ded9;
}
.ant-picker-date-panel
  .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start
  .ant-picker-cell-inner::after,
.ant-picker-date-panel
  .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end
  .ant-picker-cell-inner::after {
  background: $gold-color;
}
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start-single::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-end-near-hover::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-start-near-hover::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-end-single::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover:not(.ant-picker-cell-in-range)::after {
  border-top: 1px dashed #e1ded9;
  border-bottom: 1px dashed #e1ded9;
}
.ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover::before,
.ant-picker-cell-in-view.ant-picker-cell-range-start.ant-picker-cell-range-hover::before,
.ant-picker-cell-in-view.ant-picker-cell-range-end.ant-picker-cell-range-hover::before,
.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single).ant-picker-cell-range-hover-start::before,
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single).ant-picker-cell-range-hover-end::before,
.ant-picker-panel
  > :not(.ant-picker-date-panel)
  .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start::before,
.ant-picker-panel
  > :not(.ant-picker-date-panel)
  .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end::before {
  background: $gold-color;
}

.ant-picker-date-panel
  .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start
  .ant-picker-cell-inner::after,
.ant-picker-date-panel
  .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end
  .ant-picker-cell-inner::after {
  background: $gold-color !important;
}
.ant-picker-date-panel
  .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start
  .ant-picker-cell-inner::before,
.ant-picker-date-panel
  .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end
  .ant-picker-cell-inner::before {
  background: $gold-color !important;
}
.ant-picker-header-view button:hover {
  color: $gold-color;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: $gold-color !important;
}
