$bg-primary: #f7f7f7;

$grey-color: #aaaaa7;
$light-grey-color: #c5c7d0;
$gold-color: #b69e4f;
$light-gold-color: #e7dec2;
$dark-color: #54544d;

@font-face {
  font-family: "std-roman";
  src: url("../fonts/NewBaskervilleStd-Roman.otf");
}
@font-face {
  font-family: "std-bold";
  src: url("../fonts/NewBaskervilleStd-Bold.otf");
}
@font-face {
  font-family: "std-italic";
  src: url("../fonts/NewBaskervilleStd-Italic.otf");
}

@font-face {
  font-family: "SukhumvitSet-light";
  src: url("../fonts/SukhumvitSet-Light.ttf");
}
@font-face {
  font-family: "SukhumvitSet-bold";
  src: url("../fonts/SukhumvitSet-Bold.ttf");
}
@font-face {
  font-family: "SukhumvitSet-medium";
  src: url("../fonts/SukhumvitSet-Medium.ttf");
}

html,
body * {
  font-family: "std-roman";
  --antd-wave-shadow-color: $gold-color;
  html[lang="th"] & {
    font-family: "SukhumvitSet-medium";
  }
  // font-family: "SukhumvitSet-light";
}
h1,
h2,
h3,
h4,
h5,
h6,
span,
p {
  color: #161616;
}
#root {
  height: 100vh;
  width: 100vw;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px white;
  border-radius: 30px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #424e63;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #61718e;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}
.ant-input-affix-wrapper {
  background-color: transparent !important;
}
.ant-form-item-has-error {
  input {
    background-color: transparent !important;
  }
}

@import "antd/dist/antd.css";
@import "./ulity.scss";
@import "./layout.scss";
@import "./antd_override.scss";
@import "./style.scss";
@import "./form.scss";
