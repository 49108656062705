.layout-sider {
  width: 320px !important;
  min-width: 320px !important;
  max-width: 320px !important;
  overflow-y: auto;
}
.layout-navbar {
  min-height: 80px !important;
  max-height: 80px !important;
  height: 80px !important;
}
.navbar-select {
  height: 100%;
  .ant-select-selector {
    height: 100% !important;
    background-color: transparent !important;
    display: flex;
    align-items: center;
    text-align: center;
    border: 1px solid white !important;
  }
  .ant-select-selection-item {
    padding: 0 !important;
    color: white;
  }
  .anticon {
    color: white !important;
    font-size: 10px !important;
  }
}
.sider-navlink {
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: 0.3s;
  position: relative;

  p {
    color: $grey-color;
    font-size: 16px;
    margin-block-start: 0.3rem;
  }
  svg * {
    fill: #aaaaa7;
  }
  svg {
    width: 22px;
  }
}
.sider-navlink-active {
  background-color: #eeeded;
  p {
    color: black;
    font-weight: bold;
  }
  svg * {
    fill: #b59d4e;
  }
}
.sider-navlink-active::before {
  content: "";
  background-color: #242530;
  position: absolute;
  left: 0;
  height: 78px;
  width: 5px;
}
.sider-navlink-children {
  svg * {
    fill: transparent !important;
  }
}
.sider-navlink:hover {
  @extend .sider-navlink-active;
}
.sider-new-count {
  padding-top: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: #ac1700;
  right: 30px;
  position: absolute;
  color: white;
}
