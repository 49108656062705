.button-primary {
  background-color: #e1ded9;
  border-color: #e1ded9;
  span {
    color: #54544d;
  }
}
.button-primary:hover,
.button-primary:active,
.button-primary:focus {
  background-color: #f9f7f2;
  border-color: #f9f7f2;
  span {
    color: #54544d;
  }
}

.button-primary[disabled] {
  background-color: #f9f7f2;
  border-color: #f9f7f2;
  opacity: 0.5;
  span {
    color: #54544d;
  }
}

.button-primary[disabled]:hover,
.button-primary[disabled]:active,
.button-primary[disabled]:focus {
  background-color: #f9f7f2;
  border-color: #f9f7f2;
  opacity: 0.5;
  span {
    color: #54544d;
  }
}

.button-submit {
  background-color: #2f2b27;
  border-color: #2f2b27;
  span {
    color: white;
  }
}
.button-submit:hover,
.button-submit:active,
.button-submit:focus {
  background-color: #47413b;
  border-color: #47413b;
  span {
    color: white;
  }
}

.button-save {
  background-color: #54544d;
  border-color: #54544d;
  span {
    color: white;
  }
}
.button-save:hover,
.button-save:active,
.button-save:focus {
  background-color: #6b6b62;
  border-color: #6b6b62;
  span {
    color: white;
  }
}

.button-cancel {
  background-color: #a9a9a6;
  border-color: #a9a9a6;
  span {
    color: white;
  }
}
.button-cancel:hover,
.button-cancel:active,
.button-cancel:focus {
  background-color: #c1c1bf;
  border-color: #c1c1bf;
  span {
    color: white;
  }
}

.button-connect {
  background-color: #333333;
  border-color: #333333;
  span {
    color: #b69e4f;
  }
}
.button-connect:hover,
.button-connect:active,
.button-connect:focus {
  background-color: #333333;
  border-color: #333333;
  span {
    color: #b69e4f;
  }
}

.button-log-out {
  background-color: #deb961;
  border-color: #deb961;
  span {
    color: white;
  }
}
.button-log-out:hover,
.button-log-out:active,
.button-log-out:focus {
  background-color: #deb961;
  border-color: #deb961;
  span {
    color: white;
  }
}

.full-size-select {
  width: 100% !important;
  height: 100% !important;

  .ant-select-selector {
    display: flex;
    align-items: center;
    width: 100% !important;
    height: 100% !important;
  }
}

.page-header-icon {
  transition: 0.3s ease-out;
  svg * {
    transition: 0.3s ease-out;
  }
}

.bin-icon:hover,
.bin-icon:active,
.bin-icon:focus {
  @extend .page-header-icon;
  border-color: #a8071a;
  svg * {
    fill: #a8071a;
  }
}
.line-icon:hover,
.line-icon:active,
.line-icon:focus {
  border-color: #73d13d;
  svg * {
    fill: #73d13d;
  }
}
.excel-icon:hover,
.excel-icon:focus,
.excel-icon:active {
  border-color: #237804;
  svg * {
    fill: #237804;
  }
}

.pdf-icon:hover,
.pdf-icon:focus,
.pdf-icon:active {
  border-color: #760c0c;
  svg * {
    fill: #760c0c;
  }
}

.page-filter-button {
  width: 100%;
  height: 50px;
  background-color: #d8d8d7;
  cursor: pointer;
  border: 1px solid #d8d8d7;
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    color: $dark-color;
  }
  transition: 0.2s linear;
}
.page-filter-button-active {
  background-color: $dark-color;
  border: 1px solid $dark-color;
  span {
    color: white;
  }
}
.page-filter-button:hover,
.page-filter-button:active,
.page-filter-button:focus {
  @extend .page-filter-button-active;
}

.custom-table {
  th {
    background-color: white !important;
    font-weight: bold !important;
  }
  .ant-table-title,
  .ant-table-footer {
    padding: 16px 0px;
    background-color: white;
  }
  .ant-pagination-disabled {
    .ant-pagination-item-link {
      border-color: $light-gold-color;
    }
    .anticon {
      color: $light-gold-color !important;
    }
  }
  .ant-pagination-item-link {
    border-color: $gold-color;
  }
  .ant-pagination-item {
    border-color: $light-gold-color;
    a {
      color: $light-gold-color;
    }
  }
  .ant-pagination-item-active {
    border-color: $gold-color;
    a {
      color: $gold-color;
    }
  }
  .ant-pagination-item:hover {
    border-color: $gold-color;
    a {
      color: $gold-color;
    }
  }
  .anticon {
    color: $gold-color;
  }
  .ant-select-selector {
    border-color: $gold-color !important;
    span {
      color: $gold-color;
    }
  }
  .ant-pagination-item-link-icon * {
    color: $gold-color;
  }
  .ant-pagination-item-ellipsis {
    color: $light-gold-color !important;
  }
  .ant-pagination-item-link:hover {
    border-color: $light-gold-color;
  }

  .ant-table-tbody > tr.ant-table-row-selected > td {
    background: #f9f9f9;
  }
  .ant-checkbox-indeterminate .ant-checkbox-inner::after {
    background-color: $gold-color;
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: $gold-color;
    border-color: $gold-color;
  }
  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: $gold-color;
  }
  .ant-checkbox-checked::after {
    border: 1px solid $gold-color;
  }
}

.table-action-button {
  @extend .mr-1, .ml-1;
  height: 30px;
  width: 30px;
  border: 1px solid #e6e9ef;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 0.3s linear;
  svg * {
    fill: #aaaaa7;
  }
}
.table-action-button:hover {
  background-color: #e6e9ef;
  svg * {
    fill: $gold-color;
  }
}

// .ant-spin-blur {
//   opacity: 0.2 !important;
// }

.ant-spin-nested-loading > div > .ant-spin .ant-spin-dot {
  margin: -20px !important;
}

.custom-input {
  .ant-form-item-label
    > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    color: #b69e4f !important;
    font-family: "std-bold" !important;
  }

  .ant-input {
    line-height: 2.5;
    border: 1px solid #e4e9f2 !important;
    border-radius: 0px;
  }
  .ant-input:focus {
    box-shadow: unset !important;
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px #ffffff inset !important;
  }
}

.custom-input-number {
  .ant-form-item-label
    > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    color: #b69e4f !important;
    font-family: "std-bold" !important;
  }

  .ant-input-number-group-wrapper {
    width: 100%;
  }

  .ant-input-number {
    line-height: 3.115;
    border: 1px solid #e4e9f2;
    border-radius: 0px;
    width: 100%;
  }

  .ant-input-number:focus {
    box-shadow: unset !important;
  }

  .ant-input-number:focus,
  .ant-input-number-focused {
    box-shadow: none;
  }

  .ant-input-number-handler:hover .ant-input-number-handler-up-inner,
  .ant-input-number-handler:hover .ant-input-number-handler-down-inner {
    color: #b59d4e !important;
  }
}

.custom-input-number-no-border {
  @extend .custom-input-number;

  .ant-input-number {
    border: 1px solid transparent;
  }
}

.custom-select-ant {
  .ant-select-selector {
    display: flex;
    align-items: center;
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: 1px solid #e4e9f2 !important;
    border-radius: 0px;
    box-shadow: unset !important;
  }

  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    width: 100%;
    height: 45px;
    padding: 0 11px;
  }

  .ant-form-item-label
    > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    color: #b69e4f !important;
    font-family: "std-bold" !important;
  }

  .ant-select-selection-overflow {
    min-height: 41px;
  }

  .ant-select-item-option-disabled {
    color: rgb(151 151 151 / 84%);
    background-color: #f6f6f6 !important;
  }
}

.custom-input-password {
  .ant-form-item-label
    > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    color: #b69e4f !important;
    font-family: "std-bold" !important;
  }

  .ant-input-affix-wrapper {
    line-height: 2.5;
    border: 1px solid #e4e9f2 !important;
    border-radius: 0px;
  }
  .ant-input-affix-wrapper:focus,
  .ant-input-affix-wrapper-focused {
    box-shadow: none;
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px #ffffff inset !important;
  }
}

.custom-ant-picker {
  width: 100% !important;
  height: 100% !important;
  .ant-select-selector {
    display: flex;
    align-items: center;
    width: 100% !important;
    height: 100% !important;
  }
  .ant-picker {
    border: 1px solid #e4e9f2;
    border-radius: 0px;
    box-shadow: unset !important;
  }
  .ant-picker-now * {
    color: $gold-color;
  }
  .ant-picker-ok * {
    color: white;
  }
  .ant-btn-primary {
    border-color: $gold-color;
    background-color: $gold-color;
  }

  .ant-btn-primary:hover,
  .ant-btn-primary:focus,
  .ant-btn-primary:active {
    color: rgba(0, 0, 0, 0.25);
    border-color: #ceb35a;
    background: #ceb35a;
  }
  .ant-btn-primary[disabled],
  .ant-btn-primary[disabled]:hover,
  .ant-btn-primary[disabled]:focus,
  .ant-btn-primary[disabled]:active {
    color: rgba(0, 0, 0, 0.25);
    border-color: $light-gold-color;
    background: $light-gold-color;
  }
  .ant-picker-range .ant-picker-active-bar {
    background: $gold-color;
  }
  .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
    background: $gold-color;
  }
  .ant-picker-cell-in-view.ant-picker-cell-today
    .ant-picker-cell-inner::before {
    border: 1px solid $gold-color;
  }
  .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before,
  .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before {
    background-color: #e1ded9;
  }
  .ant-picker-cell-in-view.ant-picker-cell-in-range::before {
    background: #e1ded9;
  }
  .ant-picker-time-panel-column
    > li.ant-picker-time-panel-cell-selected
    .ant-picker-time-panel-cell-inner {
    background: #e1ded9;
  }
  tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:first-child::after,
  tr
    > .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:first-child::after,
  .ant-picker-cell-in-view.ant-picker-cell-start.ant-picker-cell-range-hover-edge-start.ant-picker-cell-range-hover-edge-start-near-range::after,
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-start:not(.ant-picker-cell-range-hover-edge-start-near-range)::after,
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-start::after {
    border-left: 1px dashed #e1ded9;
  }
  tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:last-child::after,
  tr
    > .ant-picker-cell-in-view.ant-picker-cell-range-hover-start:last-child::after,
  .ant-picker-cell-in-view.ant-picker-cell-end.ant-picker-cell-range-hover-edge-end.ant-picker-cell-range-hover-edge-end-near-range::after,
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-end:not(.ant-picker-cell-range-hover-edge-end-near-range)::after,
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-end::after {
    border-right: 1px dashed #e1ded9;
  }
  .ant-picker-date-panel
    .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start
    .ant-picker-cell-inner::after,
  .ant-picker-date-panel
    .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end
    .ant-picker-cell-inner::after {
    background: $gold-color;
  }
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-start:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after,
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after,
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start-single::after,
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-end-near-hover::after,
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-start-near-hover::after,
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-end-single::after,
  .ant-picker-cell-in-view.ant-picker-cell-range-hover:not(.ant-picker-cell-in-range)::after {
    border-top: 1px dashed #e1ded9;
    border-bottom: 1px dashed #e1ded9;
  }
  .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover::before,
  .ant-picker-cell-in-view.ant-picker-cell-range-start.ant-picker-cell-range-hover::before,
  .ant-picker-cell-in-view.ant-picker-cell-range-end.ant-picker-cell-range-hover::before,
  .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single).ant-picker-cell-range-hover-start::before,
  .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single).ant-picker-cell-range-hover-end::before,
  .ant-picker-panel
    > :not(.ant-picker-date-panel)
    .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start::before,
  .ant-picker-panel
    > :not(.ant-picker-date-panel)
    .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end::before {
    background: $gold-color;
  }

  .ant-picker-date-panel
    .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start
    .ant-picker-cell-inner::after,
  .ant-picker-date-panel
    .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end
    .ant-picker-cell-inner::after {
    background: $gold-color !important;
  }
  .ant-picker-date-panel
    .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start
    .ant-picker-cell-inner::before,
  .ant-picker-date-panel
    .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end
    .ant-picker-cell-inner::before {
    background: $gold-color !important;
  }
  .ant-picker-header-view button:hover {
    color: $gold-color;
  }
}

.custom-ant-upload-image {
  // .ant-upload-list-item-actions {
  //   a:first-child {
  //     // display: none;
  //   }
  // }
  .ant-upload-list-picture-card .ant-upload-list-item-error {
    padding: 8px !important;
    border: 2px dashed #d9d9d9 !important;
  }
  .ant-upload-list-picture-card .ant-upload-list-item {
    position: relative;
    height: "100%";
    padding: 8px;
    border: 2px dashed #d9d9d9;
    border-radius: 0px;
  }
  .ant-upload.ant-upload-select-picture-card {
    width: 167px;
    height: 167px;
    margin-right: 8px;
    margin-bottom: 8px;
    text-align: center;
    vertical-align: top;
    background-color: transparent;
    border: 2px dashed #d9d9d9;
    border-radius: 2px;
    cursor: pointer;
    transition: border-color 0.3s;
  }
  .ant-upload-list-picture-card-container {
    width: 167px;
    height: 167px;
    margin: 0px 10px 10px 0px !important;
  }
}
.web-login-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 26px;
  .ant-input {
    font-size: 26px;
    margin-left: 25px;
  }
  .ant-input-prefix {
    color: #b5b5b5;
  }
  .ant-input-affix-wrapper {
    width: 60% !important;
    height: 60px !important;
    font-size: 26px;
    border-color: #ffffff;
    border-bottom: solid 3px #b5b5b5;
  }
  .ant-input-affix-wrapper:hover,
  .ant-input-affix-wrapper:active,
  .ant-input-affix-wrapper:focus {
    border-color: #ffffff;
    border-bottom: solid 3px #b79965;
    box-shadow: none !important;
    svg * {
      fill: #b79965;
    }
  }
  .ant-form-item-control-input-content {
    display: flex;
    justify-content: center;
  }
  .ant-btn {
    font-size: 26px;
    font-weight: 500;
    width: 200px !important;
    height: 80px !important;
  }
}

.web-forgot-password-form {
  @extend .web-login-form;
  .ant-input-prefix {
    margin-left: 12px;
  }
  .ant-input-affix-wrapper {
    width: 100% !important;
    border-color: #b79965;
    border-bottom: solid 1px #b79965;
    svg * {
      fill: #b79965;
    }
  }
  .ant-input-affix-wrapper:hover,
  .ant-input-affix-wrapper:active,
  .ant-input-affix-wrapper:focus {
    border-color: #b79965;
    border-bottom: solid 1px #b79965;
    box-shadow: none !important;
  }
  .ant-btn {
    font-size: 26px;
    font-weight: 500;
    width: 100% !important;
    height: 60px !important;
  }
}

.mobile-forgot-password-form {
  @extend .web-forgot-password-form;
  .ant-input-affix-wrapper {
    width: 100% !important;
    height: 50px !important;
    font-size: 18px;
  }
  .ant-btn {
    font-size: 18px;
    font-weight: 500;
    width: 100% !important;
    height: 50px !important;
    background-color: #54544d;
    border-color: #54544d;
  }
}

.web-new-password-form {
  @extend .web-login-form;
  .ant-input-prefix {
    margin-left: 12px;
  }
  .ant-input-affix-wrapper {
    width: 100% !important;
    border-color: #b79965;
    border-bottom: solid 1px #b79965;
    svg * {
      fill: #b79965;
    }
  }
  .ant-input-affix-wrapper:hover,
  .ant-input-affix-wrapper:active,
  .ant-input-affix-wrapper:focus {
    border-color: #b79965;
    border-bottom: solid 1px #b79965;
    box-shadow: none !important;
  }
  .ant-btn {
    font-size: 26px;
    font-weight: 500;
    width: 100% !important;
    height: 60px !important;
  }
}

.mobile-new-password-form {
  @extend .web-new-password-form;
  .ant-input-affix-wrapper {
    width: 100% !important;
    height: 50px !important;
    font-size: 16px;
  }
  .ant-btn {
    font-size: 18px;
    font-weight: 500;
    width: 100% !important;
    height: 50px !important;
    background-color: #54544d;
    border-color: #54544d;
  }
  .ant-input {
    font-size: 14px !important;
  }
}

.mobile-login-form {
  @extend .web-login-form;
  font-size: 14px;
  .ant-input {
    font-size: 18px;
    margin-left: 8px;
  }
  .ant-input-prefix {
    margin-left: 0px;
  }
  .ant-input-affix-wrapper {
    width: 70% !important;
    height: 50px !important;
    font-size: 18px;
    border-color: #ffffff;
    border-bottom: solid 1px #b5b5b5;
  }
  .ant-input-affix-wrapper:hover,
  .ant-input-affix-wrapper:active,
  .ant-input-affix-wrapper:focus {
    border-color: #ffffff;
    border-bottom: solid 1px #b79965;
    box-shadow: none !important;
    svg * {
      fill: #b79965;
    }
  }
  .ant-btn {
    font-size: 18px;
    font-weight: 500;
    width: 30% !important;
    height: 50px !important;
    background-color: #54544d;
    border-color: #54544d;
  }
}

.custom-quill-text-editor {
  .ql-toolbar.ql-snow {
    border: 1px solid #e4e9f2 !important;
  }
  .ql-container.ql-snow {
    border: 1px solid #e4e9f2 !important;
  }
  .ql-toolbar.ql-snow + .ql-container.ql-snow {
    border-top: 0px !important;
  }
  .ql-snow.ql-toolbar button:hover,
  .ql-snow .ql-toolbar button:hover,
  .ql-snow.ql-toolbar button:focus,
  .ql-snow .ql-toolbar button:focus,
  .ql-snow.ql-toolbar button.ql-active,
  .ql-snow .ql-toolbar button.ql-active,
  .ql-snow.ql-toolbar .ql-picker-label:hover,
  .ql-snow .ql-toolbar .ql-picker-label:hover,
  .ql-snow.ql-toolbar .ql-picker-label.ql-active,
  .ql-snow .ql-toolbar .ql-picker-label.ql-active,
  .ql-snow.ql-toolbar .ql-picker-item:hover,
  .ql-snow .ql-toolbar .ql-picker-item:hover,
  .ql-snow.ql-toolbar .ql-picker-item.ql-selected,
  .ql-snow .ql-toolbar .ql-picker-item.ql-selected {
    color: #b79965 !important;
  }

  .ql-snow.ql-toolbar button:hover .ql-stroke,
  .ql-snow .ql-toolbar button:hover .ql-stroke,
  .ql-snow.ql-toolbar button:focus .ql-stroke,
  .ql-snow .ql-toolbar button:focus .ql-stroke,
  .ql-snow.ql-toolbar button.ql-active .ql-stroke,
  .ql-snow .ql-toolbar button.ql-active .ql-stroke,
  .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke,
  .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke,
  .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
  .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke,
  .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke,
  .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke,
  .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
  .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
  .ql-snow.ql-toolbar button:hover .ql-stroke-miter,
  .ql-snow .ql-toolbar button:hover .ql-stroke-miter,
  .ql-snow.ql-toolbar button:focus .ql-stroke-miter,
  .ql-snow .ql-toolbar button:focus .ql-stroke-miter,
  .ql-snow.ql-toolbar button.ql-active .ql-stroke-miter,
  .ql-snow .ql-toolbar button.ql-active .ql-stroke-miter,
  .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
  .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
  .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
  .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
  .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
  .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
  .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter,
  .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter {
    stroke: #b79965 !important;
  }
  .ql-snow.ql-toolbar button:hover .ql-fill,
  .ql-snow .ql-toolbar button:hover .ql-fill,
  .ql-snow.ql-toolbar button:focus .ql-fill,
  .ql-snow .ql-toolbar button:focus .ql-fill,
  .ql-snow.ql-toolbar button.ql-active .ql-fill,
  .ql-snow .ql-toolbar button.ql-active .ql-fill,
  .ql-snow.ql-toolbar .ql-picker-label:hover .ql-fill,
  .ql-snow .ql-toolbar .ql-picker-label:hover .ql-fill,
  .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-fill,
  .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-fill,
  .ql-snow.ql-toolbar .ql-picker-item:hover .ql-fill,
  .ql-snow .ql-toolbar .ql-picker-item:hover .ql-fill,
  .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-fill,
  .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-fill,
  .ql-snow.ql-toolbar button:hover .ql-stroke.ql-fill,
  .ql-snow .ql-toolbar button:hover .ql-stroke.ql-fill,
  .ql-snow.ql-toolbar button:focus .ql-stroke.ql-fill,
  .ql-snow .ql-toolbar button:focus .ql-stroke.ql-fill,
  .ql-snow.ql-toolbar button.ql-active .ql-stroke.ql-fill,
  .ql-snow .ql-toolbar button.ql-active .ql-stroke.ql-fill,
  .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
  .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
  .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
  .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
  .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
  .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
  .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill,
  .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill {
    fill: #b79965 !important;
  }
}

.button-logout-mobile {
  border-color: #b69e4f;
  span {
    color: #b69e4f;
  }
}
.button-logout-mobile:hover,
.button-logout-mobile:active,
.button-logout-mobile:focus {
  border-color: #b69e4f;
  span {
    color: #b69e4f;
  }
}

.button-scan-mobile {
  border-color: #d5cfc8;
  background-color: #d5cfc8;
  span {
    color: #5c5c55;
  }
}
.button-scan-mobile:hover,
.button-scan-mobile:active,
.button-scan-mobile:focus {
  border-color: #d5cfc8;
  background-color: #d5cfc8;
  span {
    color: #5c5c55;
  }
}

.button-back-mobile {
  border-color: transparent !important;
  background-color: transparent !important;
  box-shadow: unset !important;
  font-size: 18px;
  color: transparent !important;
  span {
    color: white;
  }
}
.button-back-mobile:hover,
.button-back-mobile:active,
.button-back-mobile:focus {
  border-color: transparent !important;
  background-color: transparent !important;
  box-shadow: unset !important;
  font-size: 18px;
  color: transparent !important;
  span {
    color: white;
  }
}

.custom-scan-qr {
  section {
    height: 100% !important;
    padding-top: unset !important;
    padding-bottom: unset !important;
    div {
      border: 10px solid rgba(0, 0, 0, 0.3) !important;
      border-width: 20vh 10vw 40vh 10vw !important;
      box-shadow: white 0px 0px 0px 5px inset !important ;
    }
  }
}
.button-primary-boederless {
  // background-color: #e1ded9;
  border-color: #e1ded9;
  span {
    color: $dark-color;
  }
}

.button-primary-boederless:hover,
.button-primary-boederless:focus,
.button-primary-boederless:active {
  border-color: $dark-color;
  span {
    color: $dark-color;
  }
}
.role-modal-switch > div {
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  height: 50px;
}
.role-modal-switch:last-child {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.image-profile-upload {
  width: 150px;
  height: 150px;
  position: relative;
  margin: auto;
  cursor: pointer;
  .addition {
    position: absolute;
    bottom: 0px;
    height: 22px;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    text-align: center;
  }
  .ant-image {
    height: 100%;
    width: 100%;
    img {
      height: 100%;
      width: 100%;
    }
  }
}

.z-index-button {
  position: absolute !important;
  z-index: 5 !important;
}

.line-border {
  position: absolute !important;
  z-index: 1 !important;
  width: 100% !important;
  height: 50% !important;
  animation-name: move, spin;
  animation-duration: 3s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  padding-left: 2.7rem !important;
  padding-right: 2.7rem !important;
  .ant-divider {
    color: transparent !important;
    border-top: 2px solid red !important;
    box-shadow: red 0px 0px 20px 2px !important;
    margin: 20.5vh 0vw 40vh 0vw !important;
  }
}

@keyframes move {
  0% {
    top: 0px;
  }
  100% {
    top: 39vh;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(0deg);
  }
}

.custom-input-mobile {
  .ant-input {
    color: #beb070;
  }
  .ant-select-selection-item {
    color: #beb070;
  }
  .ant-input[disabled] {
    background-color: white;
  }
}

.block-qr {
  border: solid 1px #e4e9f2;
  width: 200px;
  height: 200px;
}

.frame-no-background {
  border: 1px solid #b69e4f;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #b69e4f;
  height: 30px;
  width: 100%;
  .ant-typography {
    color: #b69e4f;
    overflow-wrap: break-word;
  }
}

.frame-no-background-cancel {
  border: 1px solid #ff0000;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ff0000;
  height: 30px;
  width: 100%;
  .ant-typography {
    color: #ff0000;
    overflow-wrap: break-word;
  }
}

.frame-no-background-paid {
  border: 1px solid #c2890f;
  background-color: #c2890f;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  height: 30px;
  width: 100%;
  .ant-typography {
    color: white;
    overflow-wrap: break-word;
  }
}

.frame-background {
  border: 1px solid #b69e4f;
  background-color: #b69e4f;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  height: 30px;
  width: 100%;
  .ant-typography {
    color: white;
    overflow-wrap: break-word;
  }
}

.frame-default-background {
  border: 1px solid #e4e9f2;
  background-color: #e4e9f2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: black;
  height: 30px;
  width: 100%;
}

.frame-background-pending {
  border: 1px solid #526486;
  background-color: #526486;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 100%;
  color: white;
  .ant-typography {
    color: white;
    overflow-wrap: break-word;
  }
}

.frame-background-checking {
  border: 1px solid #aeb7ff;
  background-color: #aeb7ff;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 100%;
  color: white;
  .ant-typography {
    color: white;
    overflow-wrap: break-word;
  }
}

.frame-background-waiting {
  border: 1px solid #84c7ff;
  background-color: #84c7ff;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 100%;
  color: white;
  .ant-typography {
    color: white;
    overflow-wrap: break-word;
  }
}

.frame-background-processing {
  border: 1px solid #f2c663;
  background-color: #f2c663;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 100%;
  color: white;
  .ant-typography {
    color: white;
    overflow-wrap: break-word;
  }
}

.frame-background-cancel {
  border: 1px solid #e37c69;
  background-color: #e37c69;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 100%;
  color: white;
  .ant-typography {
    color: white;
    overflow-wrap: break-word;
  }
}

.frame-background-complete {
  border: 1px solid #55b49e;
  background-color: #55b49e;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 100%;
  color: white;
  .ant-typography {
    color: white;
    overflow-wrap: break-word;
  }
}

.frame-background-paid {
  border: 1px solid #b69e4f;
  background-color: #b69e4f;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 100%;
  color: white;
  .ant-typography {
    color: white;
    overflow-wrap: break-word;
  }
}

.frame-background-overdue {
  border: 1px solid #6f4444;
  background-color: #6f4444;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 100%;
  color: white;
  .ant-typography {
    color: white;
    overflow-wrap: break-word;
  }
}

.frame-background-common {
  border: 1px solid #dedfe3;
  background-color: #dedfe3;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 100%;
}

.frame-background-private {
  border: 1px solid #232530;
  background-color: #232530;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 100%;
  color: white;
  .ant-typography {
    color: white;
    overflow-wrap: break-word;
  }
}

.color-font-waiting {
  color: #9b59e7;
}

.color-font-processing {
  color: #fdc325;
}

.color-font-cancel {
  color: #e72b2b;
}

.color-font-complete {
  color: #73c336;
}

.fixed-input-size {
  // height: 50px;
  .ant-input {
    height: 45px;
  }
  .ant-select {
    height: 45px;
    .ant-select-selector {
      height: 100%;
      display: flex;
      align-items: center;
    }
  }
  .ant-picker {
    height: 45px;
    width: 100%;
  }
  .ant-input-password {
    padding: 0px 10px;
  }
}

.cover {
  position: relative;
  bottom: 26px;
  left: 65px;
  color: #b69e4f;
  font-size: 16px;
  text-align: start;
  z-index: 1;
  width: fit-content;
}

.lable-custom {
  .ant-form-item-label > label {
    color: white !important;
  }
}

.custom-ant-date-picker {
  width: 100% !important;
  height: 100% !important;
  .ant-select-selector {
    display: flex;
    align-items: center;
    width: 100% !important;
    height: 100% !important;
  }
  .ant-picker {
    border: 1px solid #e4e9f2 !important;
    border-radius: 0px;
    box-shadow: unset !important;
  }
}

.icon {
  .ant-btn-icon-only > .anticon {
    display: flex;
    justify-content: flex-start;
  }
}

.custom-ant-time-picker {
  .ant-select-selector {
    display: flex;
    align-items: center;
    width: 100% !important;
    height: 100% !important;
  }
  .ant-picker {
    border: 1px solid #e4e9f2 !important;
    border-radius: 0px;
    box-shadow: unset !important;
    width: 100%;
    height: 45px;
  }
}

.login-form {
  button {
    @extend .button-submit;
    height: 50px;
    width: 120px;
    display: block;
    margin: auto;
    font-size: 18px;
  }
  a {
    font-size: 16px;
  }
  .ant-input {
    background-color: #e1ded9;
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px #e1ded9 inset !important;
  }
  .ant-input-affix-wrapper {
    height: 50px;
    padding: 0px;
    border: none;
    padding-bottom: 10px;
    border-bottom: 3px solid #a9a9a5;
    background-color: #e1ded9;
    .ant-input-prefix {
      color: #a9a9a5;
      font-size: 26px;
    }
    svg * {
      fill: #a9a9a5;
    }
  }
  input {
    @extend .pl-3;
    font-size: 20px;
    outline: none !important;
  }
  .ant-input-affix-wrapper:focus,
  .ant-input-affix-wrapper:active,
  .ant-input-affix-wrapper:hover,
  .ant-input-affix-wrapper:focus-within {
    border-color: #e1ded9;
    outline: 0 !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    border-bottom: 3px solid #54544d;
    .ant-input-prefix {
      color: #54544d;
      font-size: 26px;
    }
    svg * {
      fill: #54544d;
    }
  }
  input::-webkit-input-placeholder {
    // @extend .pl-2;
    font-size: 20px;
  }
  .ant-form-item-explain {
    position: absolute;
    bottom: -30px;
  }
}
.login-form-mobile {
  button {
    @extend .button-submit;
    height: 30px;
    width: 100px;
    display: block;
    margin: auto;
    // font-size: 18px;
  }
  .ant-input {
    background-color: #e1ded9;
  }
  .ant-input-prefix {
    color: #b79965;
    font-size: 18px;
  }
  .ant-input-affix-wrapper {
    height: 45px;
    padding: 2px;
    border: none;
    border-bottom: 1px solid #b79965;
    width: 60vw;
    background-color: #e1ded9;
    .ant-input-prefix {
      color: #a9a9a5;
      font-size: 26px;
    }
  }
  input {
    // @extend .pl-3;
    font-size: 16px;
    outline: none !important;
  }
  .ant-input-affix-wrapper:focus,
  .ant-input-affix-wrapper:active,
  .ant-input-affix-wrapper:hover,
  .ant-input-affix-wrapper:focus-within {
    background-color: #e1ded9;
    outline: 0 !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    border-bottom: 1px solid #b79965;
    .ant-input-prefix {
      color: #54544d;
      font-size: 26px;
    }
  }
  input::-webkit-input-placeholder {
    // @extend .pl-2;
    font-size: 14px;
  }
  .ant-form-item-explain {
    position: absolute;
    bottom: -30px;
    font-size: 12px;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px #e1ded9 inset !important;
  }
}

.custom-tabs {
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: $gold-color;
  }
  .ant-tabs-ink-bar {
    background-color: $gold-color;
  }
  .ant-tabs-tab:hover {
    color: $gold-color;
  }
  .ant-tabs-tab:focus {
    color: $gold-color;
  }
  .ant-tabs-tab-btn:focus,
  .ant-tabs-tab-remove:focus,
  .ant-tabs-tab-btn:active,
  .ant-tabs-tab-remove:active {
    color: $gold-color;
  }
}
.line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-all;
}

.result {
  .ant-result-title {
    color: $gold-color;
    font-size: 24px;
    line-height: 1.8;
    text-align: center;
  }
  .ant-result-subtitle {
    color: $gold-color;
    font-size: 18px;
    line-height: 1.6;
    text-align: center;
  }
}

.custom-ant-switch {
  .ant-switch-checked {
    background-color: $gold-color;
  }
  .ant-switch-checked:focus {
    box-shadow: 0 0 0 2px $light-gold-color;
  }
}

.navbar {
  position: fixed;
  overflow: hidden;
  z-index: 1;
  width: 100%;
  // margin: auto;
  top: 0;
  // border-bottom: solid 1px var(--primary-color);
  // top: 0;
  // box-shadow: 12px 0 15px -4px rgba(31, 73, 125, 0.8),
  //   -12px 0 8px -4px rgba(31, 73, 125, 0.8);
  box-shadow: 0 9px 0px 0px white, 0 -9px 0px 0px white,
    12px 0 15px -4px rgba(31, 73, 125, 0.8),
    -12px 0 15px -4px rgba(31, 73, 125, 0.8);
  background-color: white;
}

.background-mobile {
  min-height: 100%;
  background-color: #f7f7f7;
}

.switch-tab-button {
  transition: all 0.3s;
  height: 45px;
  width: 100%;
  background-color: #a6a6a2;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  color: white !important;
  // svg {
  //   font-size: 1.2rem;
  // }
}
.active .switch-tab-button {
  background-color: #54544d;
}
.switch-tab-button:hover {
  background-color: #54544d;
}

.custom-search {
  button {
    height: 45px;
    width: 45px;
    border: none;
  }
  input {
    height: 45px;
  }
}

.repairman-card {
  background-color: white;
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5rem;
  }
  .icon {
    color: $light-grey-color;
    font-weight: bold;
    font-size: 24px;
  }
  .body {
    display: flex;
    flex-direction: row;
  }
  .facility-name {
    color: black;
    font-weight: bold;
  }
  .users {
    color: $gold-color;
    font-weight: bold;
  }
  .datetime {
    margin-left: 0.5rem;
    p {
      font-size: 12px;
      color: black;
    }
  }
  .text-left {
    font-size: 14px;
    div {
      color: #333333;
      opacity: 0.5;
    }
  }
  .text-right {
    font-size: 14px;
    div {
      color: #64666b;
      opacity: 0.9;
    }
  }
  // p {
  //   margin-bottom: 0px;
  //   font-size: 12px;
  //   color: $light-grey-color;
  // }
  .ant-divider-horizontal {
    margin: 12px 0;
  }
}

.image-upload * {
  // border: none !important;
  outline: none !important;
}
.image-upload {
  width: 100%;
  height: 35vh;
  position: relative;
  margin: auto;
  background-color: white;
  .addition {
    position: absolute;
    top: -0px;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #d9d9d9;
  }
  .ant-image {
    width: 100%;
    height: 35vh;
    img {
      width: 100%;
      height: 35vh;
      object-fit: contain;
    }
  }
}

.file-upload {
  width: 167px;
  height: 167px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 2px dashed #d9d9d9;
  margin-right: 0.6rem;
  margin-bottom: 0.6rem;
  cursor: pointer;
  .addition {
    position: absolute;
    top: -0px;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .ant-image {
    width: 80%;
    padding: 0.5rem;
  }
}

.button-document {
  background-color: #64645d;
  border-color: #64645d;
  span {
    color: #b5a55c;
  }
}
.button-document:hover,
.button-document:active,
.button-document:focus {
  background-color: #64645d;
  border-color: #64645d;
  span {
    color: #b5a55c;
  }
}

.button-document[disabled] {
  background-color: #64645d;
  border-color: #64645d;
  opacity: 0.5;
  span {
    color: #b5a55c;
  }
}

.button-document[disabled]:hover,
.button-document[disabled]:active,
.button-document[disabled]:focus {
  background-color: #64645d;
  border-color: #64645d;
  opacity: 0.5;
  span {
    color: #b5a55c;
  }
}

.sigContainer {
  background-color: #fff;
  width: 100%;
  height: 30vh;
}

.sigContainer-web {
  background-color: #fff;
  width: 100%;
  height: 30vh;
  border: 1px solid #e4e9f2;
}

.button-clear {
  margin-top: 15%;
  width: 20vw;
  z-index: 1;
  position: absolute;

  box-shadow: unset;
  border-color: #f3f3f3;
  span {
    color: #b5a55c;
  }
}
.button-clear:hover,
.button-clear:active,
.button-clear:focus {
  margin-top: 15%;
  z-index: 1;
  position: absolute;
  box-shadow: unset;
  border-color: #f3f3f3;
  span {
    color: #b5a55c;
  }
}

.repairman-disable {
  span {
    color: #b5a55c;
  }
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    color: #b5a55c;
  }
  .ant-select-disabled.ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    color: rgba(0, 0, 0, 0.25);
    background: #5252520f;
    cursor: not-allowed;
  }
  .ant-select-borderless .ant-select-selector {
    background-color: #ffffff !important;
  }
  .ant-select-single.ant-select-open .ant-select-selection-item {
    color: #b5a55c;
  }
  .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    padding-right: 18px;
    color: #bfbfbf !important;
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: unset;
  }
}
.forget-password {
  width: 100%;
  .ant-input-affix-wrapper {
    height: 60px;
    width: 100%;
    background-color: #e1ded9;
    border: 1px solid #a9a9a5;
    .ant-input-prefix {
      color: #a9a9a5;
      font-size: 26px;
    }
    svg * {
      fill: #a9a9a5;
    }
  }
  .ant-input-affix-wrapper:focus,
  .ant-input-affix-wrapper:active,
  .ant-input-affix-wrapper:hover,
  .ant-input-affix-wrapper:focus-within {
    background-color: #e1ded9;
    outline: 0 !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    border: 1px solid #54544d;
    .ant-input-prefix {
      color: #54544d;
      font-size: 26px;
    }
    svg * {
      fill: #54544d;
    }
  }
  button {
    width: 100%;
    height: 60px;
    font-size: 20px;
  }
  input {
    font-size: 20px;
    background-color: #e1ded9;
  }
  .anticon {
    font-size: 24px;
    padding: 1rem;
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px #e1ded9 inset !important;
  }
  .ant-form-item-explain {
    position: absolute;
    bottom: -30px;
    left: 0px;
  }
}

.date-picker-style {
  .ant-picker {
    border: 1px solid #e4e9f2 !important;
    border-radius: 0px;
    box-shadow: unset !important;
  }
}

.custom-switch {
  .ant-switch-checked {
    background-color: #b59d4e;
  }
  .ant-switch-checked:focus {
    box-shadow: 0 0 0 2px $light-gold-color;
  }
}

.border-image {
  border: 1px solid #e4e9f2 !important;
  padding: 1%;
}

.button-add-comment {
  margin-top: -80px;
  // margin-left: 1vw;
  height: 40px;
  z-index: 1;
  position: absolute;
  box-shadow: unset;
  background-color: #54544d;
  border-color: #54544d;
  span {
    color: white;
  }
}
.button-add-comment:hover,
.button-add-comment:active,
.button-add-comment:focus {
  z-index: 1;
  position: absolute;
  box-shadow: unset;
  background-color: #54544ded;
  border-color: #54544ded;
  span {
    color: white;
  }
}

.holiday-events {
  margin: 0;
  padding: 0;
  list-style: none;
}

.main {
  position: relative;
  bottom: 52px;
  left: 249px;
  color: #b69e4f;
  font-size: 16px;
  text-align: start;
  z-index: 1;
  width: fit-content;
}

.custom-calendar {
  .ant-picker-calendar-full
    .ant-picker-panel
    .ant-picker-cell-selected
    .ant-picker-calendar-date,
  .ant-picker-calendar-full
    .ant-picker-panel
    .ant-picker-cell-selected:hover
    .ant-picker-calendar-date,
  .ant-picker-calendar-full
    .ant-picker-panel
    .ant-picker-cell-selected
    .ant-picker-calendar-date-today,
  .ant-picker-calendar-full
    .ant-picker-panel
    .ant-picker-cell-selected:hover
    .ant-picker-calendar-date-today {
    background-color: #f7f8fb;
  }
  .ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-today {
    border-color: unset;
  }
  .ant-picker-calendar-full
    .ant-picker-panel
    .ant-picker-cell-selected
    .ant-picker-calendar-date
    .ant-picker-calendar-date-value,
  .ant-picker-calendar-full
    .ant-picker-panel
    .ant-picker-cell-selected:hover
    .ant-picker-calendar-date
    .ant-picker-calendar-date-value,
  .ant-picker-calendar-full
    .ant-picker-panel
    .ant-picker-cell-selected
    .ant-picker-calendar-date-today
    .ant-picker-calendar-date-value,
  .ant-picker-calendar-full
    .ant-picker-panel
    .ant-picker-cell-selected:hover
    .ant-picker-calendar-date-today
    .ant-picker-calendar-date-value {
    color: black;
  }
  .ant-picker-calendar-full .ant-picker-panel .ant-picker-body th,
  .ant-picker-calendar-full .ant-picker-panel .ant-picker-body td {
    border: 1px solid #f2f5f9;
    span {
      margin-left: 8px;
      font-size: 12px;
    }
  }
  .ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date {
    border-top: unset;
    padding: 0;
    margin: 0;
  }
  .ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-value {
    text-align: center;
    padding-top: 16px;
    font-size: 13px;
  }
  .ant-picker-calendar-full .ant-picker-panel .ant-picker-body th {
    padding: 16px 0;
    text-align: center;
    border: 1px solid #f2f5f9;
    width: 128px;
  }
  .ant-picker-calendar .ant-picker-panel .ant-picker-body {
    padding: 0;
    border: 1px solid #f2f5f9;
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: 1px solid #e4e9f2 !important;
    border-radius: 0px;
    box-shadow: unset !important;
  }
  .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
  .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    color: $gold-color;
  }
  .ant-select-arrow {
    color: $gold-color;
  }

  .ant-radio-button-wrapper {
    span {
      color: $gold-color;
    }
  }
  .ant-radio-button-wrapper-checked:not([class*=" ant-radio-button-wrapper-disabled"]).ant-radio-button-wrapper {
    box-shadow: unset;
    border-color: $gold-color;
    background-color: $gold-color;
    span {
      color: white;
    }
  }
  .ant-radio-button-wrapper-checked:not([class*=" ant-radio-button-wrapper-disabled"]).ant-radio-button-wrapper:last-child {
    border-color: $gold-color;
  }
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
    background-color: $gold-color;
  }
}

.button-submit-setup {
  background-color: #54544d;
  border-color: #54544d;
  span {
    color: white;
  }
}
.button-submit-setup:hover,
.button-submit-setup:active,
.button-submit-setup:focus {
  background-color: #54544dc7;
  border-color: #54544dc7;
  span {
    color: white;
  }
}

.button-submit-setup[disabled] {
  background-color: #54544d;
  border-color: #54544d;
  opacity: 0.5;
  span {
    color: white;
  }
}

.button-submit-setup[disabled]:hover,
.button-submit-setup[disabled]:active,
.button-submit-setup[disabled]:focus {
  background-color: #54544d;
  border-color: #54544d;
  opacity: 0.5;
  span {
    color: white;
  }
}

.button-cancel-setup {
  background-color: #54544d;
  border-color: #54544d;
  opacity: 0.5;
  span {
    color: white;
  }
}
.button-cancel-setup:hover,
.button-cancel-setup:active,
.button-cancel-setup:focus {
  background-color: #54544db8;
  border-color: #54544db8;
  span {
    color: white;
  }
}

.button-submit-swal-error {
  background-color: #b69e4f;
  border-color: #b69e4f;
  span {
    color: white;
  }
  box-shadow: unset !important;
}
.button-submit-swal-error:hover,
.button-submit-swal-error:active,
.button-submit-swal-error:focus {
  background-color: #b5a55c;
  border-color: #b5a55c;
  span {
    color: white;
  }
  box-shadow: unset !important;
}

.button-edit {
  background-color: white;
  border-color: #54544d;
  opacity: 0.5;
  span {
    color: #54544d;
  }
}
.button-edit:hover,
.button-edit:active,
.button-edit:focus {
  background-color: white;
  border-color: #54544db8;
  span {
    color: #54544d;
  }
}

.button-delete {
  background-color: #54544d;
  border-color: #54544d;
  span {
    color: white;
  }
}
.button-delete:hover,
.button-delete:active,
.button-delete:focus {
  background-color: #54544d;
  border-color: #54544d;
  span {
    color: white;
  }
}

.booking-time-btn {
  height: 50px;
  border: 1px solid #e7e7e7;
  background-color: #e7e7e7;
  display: flex;
  align-items: center;
  justify-content: center;
}
.booking-time-btn:hover,
.booking-time-btn:active,
.booking-time-btn:focus {
  cursor: pointer;
  color: white;
  background-color: #6b6b62;
  border-color: #6b6b62;
  transition: 0.2s;
}
.active .booking-time-btn {
  color: white !important;
  background-color: #6b6b62 !important;
  border-color: #6b6b62 !important;
}
.disabled .booking-time-btn {
  color: red;
  border-color: red;
  background-color: white;
  cursor: not-allowed !important;
}

.ant-icon {
  .anticon {
    color: inherit !important;
  }
}

.table-row-pink {
  background-color: #f7e5e5 !important;
  color: black;
}

.button-clear-web {
  padding: 10px;
  width: 100%;
  height: 30px;
  background-color: white;
  cursor: pointer;
  border: 1px solid #e4e9f2;
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    color: $dark-color;
  }
  transition: 0.2s linear;
}
.button-clear-web:hover,
.button-clear-web:active,
.button-clear-web:focus {
  padding: 10px;
  width: 100%;
  height: 30px;
  background-color: white;
  cursor: pointer;
  border: 1px solid #e4e9f2;
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    color: $dark-color;
  }
  transition: 0.2s linear;
}

.button-upload {
  padding: 10px;
  width: 100%;
  height: 30px;
  background-color: white;
  cursor: pointer;
  border: 1px solid $dark-color;
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    color: $dark-color;
  }
  transition: 0.2s linear;
}
.button-upload-active {
  background-color: $dark-color;
  border: 1px solid $dark-color;
  span {
    color: white;
  }
}
.button-upload:hover,
.button-upload:active,
.button-upload:focus {
  @extend .button-upload-active;
}

.button-signature {
  padding: 10px;
  width: 100%;
  height: 30px;
  background-color: white;
  cursor: pointer;
  border: 1px solid $gold-color;
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    color: $gold-color;
  }
  transition: 0.2s linear;
}
.button-signature-active {
  background-color: $gold-color;
  border: 1px solid $gold-color;
  span {
    color: white;
  }
}
.button-signature:hover,
.button-signature:active,
.button-signature:focus {
  @extend .button-signature-active;
}

.image-border {
  border: 1px solid #e4e9f2 !important;
}

.button-refetch {
  border: 1px solid #b69e4f;
  background-color: #b69e4f;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  height: 30px;
  width: 100%;
}

.date-picker-time-style {
  .ant-picker-footer {
    .ant-picker-ranges {
      .ant-picker-now {
        a {
          color: $gold-color !important;
        }
      }
      .ant-picker-ok {
        .ant-btn-primary {
          color: #fff;
          border-color: $gold-color;
          background: $gold-color;
          span {
            color: #ffffff;
          }
        }
      }
    }
  }
}

.reference-card {
  background-color: white;
  padding: 10px;
  box-shadow: 1px 1px 5px 1px #64666b30;
  .body {
    display: flex;
    flex-direction: row;
  }
  .text-left {
    font-size: 12px;
    p {
      color: #333333;
      opacity: 0.5;
    }
  }
  .text-right {
    font-size: 12px;
    p {
      color: #64666b;
      opacity: 0.8;
    }
  }
}

.modal-import-excel {
  .ant-modal-body {
    height: 50vh;
    padding: 0;
    // width: 60vw;
  }
}

.button-note {
  background-color: $gold-color;
  border-color: $gold-color;
  span {
    color: white;
  }
}
.button-note:hover,
.button-note:active,
.button-note:focus {
  background-color: $gold-color;
  border-color: $gold-color;
  span {
    color: white;
  }
}

.button-add-comment-mobile {
  margin-top: -80px;
  height: 35px;
  z-index: 1;
  position: absolute;
  box-shadow: unset;
  background-color: #54544d;
  border-color: #54544d;
  span {
    color: white;
  }
}

.payment-dashboard {
  height: 150px;
  background: #f6f6f5;
  display: flex;
  align-items: center;
}

.button-add-comment-mobile:hover,
.button-add-comment-mobile:active,
.button-add-comment-mobile:focus {
  z-index: 1;
  position: absolute;
  box-shadow: unset;
  background-color: #54544ded;
  border-color: #54544ded;
  span {
    color: white;
  }
}

.modal-color {
  p {
    color: $gold-color !important;
  }
}

.expty-color {
  .ant-empty-img-simple-g {
    stroke: $gold-color !important;
  }
}

.navbar-mobile {
  position: fixed;
  overflow: hidden;
  z-index: 1;
  width: 100%;
  top: 0;
  background-color: white;
}

.disabled-textarea {
  .ant-input[disabled] {
    color: rgba(0, 0, 0, 0.25);
    background-color: white !important;
    border-color: white !important;
    box-shadow: none;
    cursor: not-allowed;
    opacity: 1;
  }
}

.div-crop-image {
  @extend .custom-ant-switch;
  @extend .custom-select-ant;
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 30px;
  }
  display: flex;
  align-items: center;
  position: absolute;
  left: 150px;
}

.custom-badge-dot {
  .ant-badge-status-dot {
    width: 14px;
    height: 14px;
    top: -2px;
  }
}

.frame-default-background-overflow {
  border: 1px solid #e4e9f2;
  background-color: #e4e9f2;
  color: black;
  height: 30px;
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 3px;
  text-align: center;
}
.custom-ant-check-box {
  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: $gold-color;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: $gold-color;
    border-color: $gold-color;
  }

  .ant-checkbox-checked::after {
    border-color: $gold-color;
  }
}

.time-btn * {
  transition: 0.1s;
}
.time-btn {
  span {
    color: rgb(169, 169, 166);
  }
  svg * {
    fill: rgb(169, 169, 166);
  }
}

.time-btn:hover,
.time-btn:active,
.time-btn:focus {
  border-color: #b69e4f;
  span {
    color: #b69e4f;
  }
  svg * {
    fill: #b69e4f;
  }
}

.button-create-qr {
  width: 100%;
  height: 35px;
  background-color: #54544d;
  cursor: pointer;
  border: 1px solid #54544d;
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    color: white;
  }
  transition: 0.2s linear;
}
.button-create-qr:hover,
.button-create-qr:active,
.button-create-qr:focus {
  width: 100%;
  height: 35px;
  background-color: #54544d;
  cursor: pointer;
  border: 1px solid #54544d;
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    color: white;
  }
  transition: 0.2s linear;
}

.custom-dashboard-picker-gold {
  @extend .custom-ant-picker;

  .ant-picker-input > input {
    color: $gold-color;
  }
  .ant-picker {
    border: 1px solid $gold-color !important;
  }
  .anticon svg {
    color: $gold-color;
  }
}

.custom-dashboard-picker-white {
  @extend .custom-ant-picker;

  .ant-picker-input > input {
    color: white;
  }
  .ant-picker {
    border: 1px solid white !important;
  }
  .ant-picker-range .ant-picker-active-bar {
    background: white;
  }
  .anticon svg {
    color: white;
  }
}

.custom-dashboard-picker-black {
  @extend .custom-ant-picker;

  .ant-picker-input > input {
    color: black;
  }
  .ant-picker {
    border: 1px solid black !important;
  }
  .ant-picker-input > input::placeholder {
    color: #54544d;
  }
  .ant-picker-range .ant-picker-active-bar {
    background: black;
  }
  .anticon svg {
    color: black;
  }
}

.db-border {
  border: 1px solid #e4e9f2;
}

.antd-picker-db {
  height: 30px !important;
  max-width: 200px;
  input {
    font-size: 12px;
  }
}

.custom-checbox {
  width: 100%;
  .ant-checkbox-wrappe {
    width: 100%;
  }
  .ant-checkbox + span {
    width: 100%;
  }
  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: $gold-color !important;
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: $gold-color !important;
    border-color: $gold-color !important;
  }
}
